import React from "react"
import { Link } from "gatsby"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.state = { scrolled: false }
  }

  nav = React.createRef()

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll(event) {
    let scrollTop = event.target.scrollingElement.scrollTop
    if (scrollTop > 0 && !this.state.scrolled) {
      this.setState({ scrolled: true })
    } else if (scrollTop <= 0 && this.state.scrolled) {
      this.setState({ scrolled: false })
    }
  }

  render() {
    return (
      <header
        className={"header" + (this.state.scrolled ? " scrolled" : "")}
        ref={this.nav}
      >
        <div class="header-content">
          <div class="logo">
            <span className="logo-desktop">Kjartan</span>
            <svg
              className="logo-icon"
              width="15"
              height="12"
              viewBox="0 0 15 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.98661 11L1.48661 6L6.98661 1H12.4134L7.72733 5.26006L6.91339 6L7.72733 6.73994L12.4134 11H6.98661Z"
                fill="#392109"
                stroke="#392109"
                strokeWidth="1"
              />
            </svg>

            <span className="logo-desktop">Kennedy</span>
          </div>
          <nav>
            <Link to="/">Home</Link>
            <Link to="/contact">Get in touch</Link>
          </nav>
        </div>
      </header>
    )
  }
}

export default Header
